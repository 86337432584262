<template>
  <div class="review">
    <div class="top">
      <div class="querybox">
        <div>
          <p>视频标题:</p>
          <el-input
            v-model="queryParams.videoTitle"
            placeholder="请输入视频标题"
            clearable
          ></el-input>
        </div>
        <div>
          <p>评论状态:</p>
          <el-select
            v-model="queryParams.contentReviewStatus"
            placeholder="请选择评论状态"
            clearable
          >
            <el-option
              v-for="item in getDictDatas(DICT_TYPE.CONTENT_REVIEW_STATUS)"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="handlebox">
        <!-- <el-button type="primary" class="green-btn">一键全选</el-button>
        <el-button type="primary" class="blue-btn">一键通过</el-button>
        <el-button type="primary" class="red-btn">一键拒绝</el-button> -->
        <el-button
          type="primary"
          icon="el-icon-search"
          class="blue-btn"
          @click="search"
          >查询</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          class="gray-btn"
          style="color: #1d2129"
          @click="resetFn"
          >重置</el-button
        >
        <el-button
          type="primary"
          class="blue-btn"
          icon="el-icon-delete"
          style="background: #f96868"
          @click="deteleVideos"
          >删除</el-button
        >
      </div>
    </div>
    <div class="content">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        show-overflow-tooltip
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="videoCover" label="评论视频">
          <template slot-scope="{ row }">
            <div class="logo">
              <img :src="row.videoCover" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="videoTitle" label="视频标题" min-width="120">
        </el-table-column>
        <el-table-column prop="userName" label="评论发布人"> </el-table-column>
        <el-table-column
          prop="commentContent"
          label="评论内容"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="createdTime" label="评论时间" min-width="120">
        </el-table-column>
        <el-table-column prop="contentReviewStatus" label="评论状态">
          <template slot-scope="{ row }">
            <p>
              <span v-if="row.contentReviewStatus === 0" style="color: #5e83f5"
                >待审核</span
              >
              <span v-if="row.contentReviewStatus === 1" style="color: #5e83f5"
                >正常显示</span
              >
              <span v-if="row.contentReviewStatus === 2" style="color: #ee4242"
                >审核拒绝</span
              >
              <span v-if="row.contentReviewStatus === 3" style="color: #ee4242"
                >禁止显示</span
              >
            </p>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-popconfirm
              title="该评论是否审核通过？"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(scope.row, 1)"
              v-if="Number(scope.row.contentReviewStatus) === 0"
            >
              <el-button class="textblue-btn" slot="reference">通过</el-button>
            </el-popconfirm>
            <el-popover
              placement="bottom"
              width="300"
              :ref="`popover-${scope.$index}`"
              v-if="Number(scope.row.contentReviewStatus) === 0"
            >
              <div class="log">
                <el-input
                  v-model="reviewLog"
                  type="textarea"
                  autosize
                  placeholder="请输入拒绝原因"
                ></el-input>
              </div>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="$refs[`popover-${scope.$index}`].doClose()"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="cancelOrder(scope.row, 2, scope.$index)"
                  >确定</el-button
                >
              </div>
              <el-button slot="reference" type="text" class="textred-btn"
                >拒绝</el-button
              >
            </el-popover>
            <el-popconfirm
              title="是否展示该评论？"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(scope.row, 1)"
              v-if="Number(scope.row.contentReviewStatus) === 3"
            >
              <el-button class="blue-btn" slot="reference">显示</el-button>
            </el-popconfirm>
            <el-popconfirm
              title="是否隐藏该评论？"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(scope.row, 3)"
              v-if="Number(scope.row.contentReviewStatus) === 1"
            >
              <el-button class="red-btn" slot="reference">禁止显示</el-button>
            </el-popconfirm>
            <!-- <el-button
              class="text-btn"
              type="text"
              @click="checkDetail(scope.row)"
              >查看视频</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination
        v-show="total > queryParams.pageSize"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="init"
      />
    </div>
    <!-- 查看视频 -->
    <el-dialog
      :visible.sync="videoVisible"
      :title="videoItem.title"
      width="40%"
      :before-close="handleClose"
      destroy-on-close
    >
      <div class="imgBox">
        <video :src="videoItem.video" controls />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCommentList, editComment, deleteComment } from "@/api/video";
export default {
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        videoTitle: "",
        contentReviewStatus: null,
      },
      tableData: [],
      total: 0, //总数
      videoItem: {},
      videoVisible: false,
      reviewLog: "",
      selectList: [],
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    //获取审核列表
    async init() {
      const res = await getCommentList(this.queryParams);
      this.tableData = res.result.records;
      this.total = res.result.total;
    },
    // 查询
    search() {
      this.queryParams.pageNum = 1;
      this.init();
    },
    //重置
    resetFn() {
      this.queryParams.contentReviewStatus = null;
      this.queryParams.videoTitle = "";
      this.queryParams.pageNum = 1;
      this.init();
    },
    // 查看视频
    checkDetail(row) {
      this.videoItem = row;
      this.videoVisible = true;
    },
    handleClose() {
      this.videoVisible = false;
    },
    async cancelOrder(item, status, index) {
      const params = {
        contentReviewStatus: status,
        id: item.id,
        reviewLog: status === 2 ? this.reviewLog : null,
      };
      await editComment(params);
      if (index) {
        this.$refs[`popover-${index}`].doClose();
      }
      this.init();
    },
    handleSelectionChange(val) {
      // console.log(val);
      this.selectList = val;
    },
    async deteleVideos() {
      if (this.selectList.length === 0) {
        this.$message.error("请选择要删除的评论");
        return;
      }
      const arr = this.selectList.map((el) => {
        return el.id;
      });
      await deleteComment(arr);
      this.$message.success("删除成功");
      this.queryParams.pageNum = 1;
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.review {
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0000000d;
    padding-bottom: 10px;
    ::v-deep .querybox {
      display: flex;
      align-items: center;

      > div {
        display: flex;
        align-items: center;
        margin-right: 22px;
        p {
          color: #4e5969;
          line-height: 22px;
          margin-right: 16px;
          white-space: nowrap;
        }
        .el-input,
        .el-range-editor {
          width: 256px;
        }
        input::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #86909c;
        }
        .el-input__inner,
        .el-range-input {
          background: #f7f8fa;
          border: none;
          color: #86909c;
        }
      }
      .btnbox {
        display: flex;
        .el-button {
          padding: 10px 0;
          width: 84px;
          box-sizing: border-box;
          // margin-left: 22px;
        }
      }
    }
    .handlebox {
      display: flex;
      align-items: center;
      .el-button {
        padding: 10px 0;
        width: 105px;
        box-sizing: border-box;
        // margin-left: 22px;
      }
    }
  }
  .content {
    padding-top: 20px;
    .logo {
      display: flex;
      justify-content: center;
      img {
        width: 47px;
        height: 47px;
        border-radius: 2px;
      }
    }
    .red-btn,
    .blue-btn {
      width: 80px;
      padding: 5px 0;
      box-sizing: border-box;
    }
  }
  ::v-deep(.el-dialog) {
    .el-dialog__title {
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
    }
    .el-dialog__body {
      display: flex;
      align-items: center;
      justify-content: center;
      .imgBox {
        width: 100%;
        video {
          width: 100%;
        }
      }
      .OutBtnBox {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
  .textred-btn,
  .textblue-btn {
    padding: 0 5px;
  }
}
</style>
