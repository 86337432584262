import request from "@/utils/request";
// 视频列表
const getVideoList = (params) =>
  request.post("/boxing/web/video/pageVideoList", params);
// 修改视频状态
const editVideoStatus = (params) =>
  request.post("/boxing/web/video/modifyVideoStatus", params);
// 批量删除视频
const deleteVideo = (params) =>
  request.post("/boxing/web/video/deleteVideo", params);
// 查询评论列表
const getCommentList = (params) =>
  request.post("/boxing/web/video/pageVideoCommentList", params);
// 修改评论状态
const editComment = (params) =>
  request.post("/boxing/web/video/modifyVideoCommentStatus", params);
// 删除评论
const deleteComment = (params) =>
  request.post("/boxing/web/video/batchDeleteVideoComment", params);
export {
  getVideoList,
  editVideoStatus,
  deleteVideo,
  getCommentList,
  editComment,
  deleteComment,
};
